import React, { useState, useEffect } from 'react';
import { Frame, SkeletonPage, Layout, Card, SkeletonBodyText, Loading, Button, Banner } from '@shopify/polaris';
import Api from '../helpers/api';
import { CartContextProvider } from '../context-providers/CartContext';
import { LineItemPropertiesSetter } from '../components/LineItemPropertiesSetter';

import * as Sentry from '@sentry/react';

export default function ({ lineItemIndex, productId, variantId }) {
    console.log({ lineItemIndex: lineItemIndex, productId: productId });
    const [loading, setLoading] = useState(true);
    const [productData, setProductData] = useState({});

    const [engravingProperties, setEngravingProperties] = useState({});

    const childIframeMessageListener = (eventMessage) => {
        if (eventMessage.data.engraving) {
            // These come in as {"properties[key]": "value"}
            let data = {};
            Object.entries(eventMessage.data.engraving).forEach(([key, value]) => {
                if (key.startsWith('properties')) {
                    data[key.replace(/properties\[(.+?)\]/, '$1')] = value;
                }
            });
            setEngravingProperties(data);
        }
    };

    useEffect(() => {
        Api.getProduct(productId)
            .then((response) => {
                setProductData(response.data);
                setLoading(false);
            })
            .catch((error) => {
                Sentry.captureException(error);
            });

        window.addEventListener('message', childIframeMessageListener);

        return () => {
            window.removeEventListener('message', childIframeMessageListener);
        };
    }, []);

    return (
        <Frame>
            {loading && (
                <>
                    <Loading />
                    <SkeletonPage primaryAction>
                        <Layout>
                            <Layout.Section>
                                <Card sectioned>
                                    <SkeletonBodyText />
                                </Card>
                            </Layout.Section>
                        </Layout>
                    </SkeletonPage>
                </>
            )}
            {!loading && !productData && (
                <Layout>
                    <Banner title="Sorry, there was a problem loading product data." status="warning" />
                </Layout>
            )}

            {!loading && productData && (
                <Layout>
                    <Layout.Section>
                        <iframe
                            src={`https://${window.shopDomain}/products/${productData.handle}?variant=${variantId}&view=pos`}
                            style={{ width: '100%', height: '100vh' }}
                        />
                    </Layout.Section>

                    <CartContextProvider>
                        <LineItemPropertiesSetter
                            lineItemIndex={lineItemIndex}
                            engravingProperties={engravingProperties}
                        />
                    </CartContextProvider>
                </Layout>
            )}
            {/* {productData && (
                <div
                    dangerouslySetInnerHTML={{
                        __html: JSON.stringify(productData)
                            .replaceAll(',', ',<br>')
                            .replaceAll('{', '{<div style="margin-left:1rem;">')
                            .replaceAll('}', '</div>}'),
                    }}
                />
            )} */}
        </Frame>
    );
}
